<template>
  <div id="actionDialog">
    <el-dialog
      title="操作弹窗"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form :model="form" :rules="formRules" ref="form" label-width="100px">
        <el-form-item label="税号" prop="taxNum">
          <el-input
            :disabled="!!id"
            v-model="form.taxNum"
            placeholder="请输入税号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票员" prop="clerk">
          <el-input v-model="form.clerk" placeholder="请输入开票员"></el-input>
        </el-form-item>
        <el-form-item label="销方地址" prop="salerAddress">
          <el-input
            v-model="form.salerAddress"
            placeholder="请输入销方地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="销方电话" prop="salerTel">
          <el-input
            v-model="form.salerTel"
            placeholder="请输入销方电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行及账号">
          <el-input
            v-model="form.salerAccount"
            placeholder="请输入销方开户行及账号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      id: null,
      form: {
        clerk: "",
        taxNum: "",
        salerAddress: "",
        salerTel: "",
        salerAccount: "",
      },
      formRules: {
        clerk: [{ required: true, message: "请输入开票员", trigger: "blur" }],
        taxNum: [{ required: true, message: "请输入税号", trigger: "blur" }],
        salerAddress: [
          { required: true, message: "请输入销方地址", trigger: "blur" },
        ],
        salerTel: [
          { required: true, message: "请输入销方电话", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.id = null;
      this.$set(this, "form", {
        clerk: "",
        taxNum: "",
        salerAddress: "",
        salerTel: "",
        salerAccount: "",
      });
      this.$refs["form"].resetFields();
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            let url = "/invoice/addAuthInfo";
            let params = {
              ...this.form,
            };

            if (this.id) {
              url = "/invoice/edit";
              params.id = this.id;
              delete params.taxNum;
            }
            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.getList();
              this.$message.success("操作成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
