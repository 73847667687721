<template>
  <div id="invoiceAuth">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>发票抬头</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="createInvoice"
              type="primary"
              @click="$refs.actionDialog.show = true"
              v-if="buttonList.includes('invoiceAuth-create')"
              ><i class="iconfont icon-jiahao"></i> 创建</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="taxNum" label="税号" align="center">
        </el-table-column>
        <el-table-column prop="clerk" label="开票员" align="center">
        </el-table-column>
        <el-table-column prop="salerAddress" label="销方地址" align="center">
        </el-table-column>
        <el-table-column prop="salerTel" label="销方电话" align="center">
        </el-table-column>
        <el-table-column
          prop="salerAccount"
          label="开户行及账号"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="lastAuth" label="上次授权时间" align="center">
        </el-table-column>
        <el-table-column label="是否启用" align="center">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.enable === '是'"
              @change="enableChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="buttonList.includes('invoiceAuth-edit')"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="auth(scope.row)"
              v-if="
                buttonList.includes('invoiceAuth-auth') && !scope.row.lastAuth
              "
              >授权</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <action-dialog ref="actionDialog" :getList="getList" />
  </div>
</template>
<script>
import actionDialog from "./actionDialog.vue";
export default {
  components: {
    actionDialog,
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    let query = this.$route.query;
    if (query.code && query.taxnum) {
      this.invoiceAuth(query.code, query.taxnum);
    } else {
      this.getList();
    }
  },
  methods: {
    async invoiceAuth(code, taxnum) {
      try {
        this.table.loading = true;
        let res = await this.$http.get("/invoice/info", {
          params: {
            code,
            taxnum,
          },
        });
        if (res.code === 0) {
          this.$message.success("授权成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
        this.table.loading = false;
      } catch (err) {
        this.$message.error(err);
        this.table.loading = false;
      }
    },
    async auth(row) {
      this.table.loading = true;
      let res = await this.$http.get(`/invoice/authUrl/${row.id}`);
      if (res.code === 0) {
        window.location.href = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/invoice/authInfo/list/page", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.lastAuth = item.lastAuth
              ? this.$moment(item.lastAuth).format("yyyy-MM-DD HH:mm:ss")
              : "";
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    edit(item) {
      this.$refs.actionDialog.id = item.id;
      for (let key in this.$refs.actionDialog.form) {
        this.$refs.actionDialog.form[key] = item[key];
      }
      this.$refs.actionDialog.show = true;
    },
    async enableChange(item) {
      let res = await this.$http.post("/invoice/edit", {
        id: item.id,
        enable: item.enable === "是" ? "否" : "是",
      });
      if (res.code === 0) {
        this.getList();
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style lang="scss">
#invoiceAuth {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .createInvoice {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
